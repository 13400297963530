<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { mapGetters } from "vuex";
import axios from "@/config/axios";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorData: `<p>
                              Bonjour [name], <br />
                              <br />
                              Veuillez trouver ci-joint votre bulletin de paie
                              pour la période du [start_date] au [end_date].
                              <br />
                              <br />
                              Cordialement,
                            </p>`,
      title: "Paramètres de l'application",
      current_configs: {},
    };
  },
  components: {
    ckeditor: CKEditor.component,
  },
  computed: {
    ...mapGetters("smtp", ["configs"]),
    isModified() {
      return (
        this.current_configs?.message_subject !==
          this.configs.message_subject ||
        this.current_configs?.message_from !== this.configs.message_from ||
        this.current_configs?.message_body !== this.editorData
      );
    },
  },
  beforeMount() {
    this.current_configs = { ...this.configs };
    this.editorData = this.configs.message_body;
  },
  methods: {
    save() {
      axios
        .post("/smtp-configs", {
          ...this.current_configs,
          message_body: this.editorData,
        })
        .then(() => {
          this.$saSuccess(
            "Les paramètres SMTP ont été mis à jour avec succès",
            ""
          );
        })
        .catch((err) => {
          this.$saError("Une erreur s'est produite", err.message);
        });
    },
    reset() {
      this.current_configs = { ...this.configs };
      this.editorData = this.configs.message_body;
    },
  },
};
</script>

<template>
  <form @submit.prevent="save">
    <div id="newlink">
      <div id="1">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label
                for="message_subject"
                class="form-label"
                placeholder="Entrez le sujet"
                >Sujet</label
              >
              <input
                type="text"
                class="form-control"
                id="message_subject"
                placeholder="Ex : Bulletin de paie"
                v-model="current_configs.message_subject"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="message_from" class="form-label"> Message de </label>
              <input
                type="text"
                class="form-control"
                id="message_from"
                placeholder="Ex : John Doe"
                v-model="current_configs.message_from"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-12">
            <div class="mb-3 mt-3">
              <label for="jobDescription" class="form-label"
                >Corps du message</label
              >
              <div>
                Vous pouvez utiliser les variables suivantes dans votre message
                :

                <ul>
                  <li>
                    <span class="text-primary">[start_date]</span>
                    : Date de début du mois
                  </li>
                  <li>
                    <span class="text-primary">[end_date]</span>
                    : Date de fin du mois
                  </li>
                  <li>
                    <span class="text-primary">[name]</span> : Nom de l'employé
                  </li>
                </ul>
              </div>

              <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </div>
    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-success"
          :disabled="!isModified"
          @click="save"
        >
          Enregistrer les modifications
        </button>
        <button
          type="button"
          class="btn btn-soft-warning"
          :disabled="!isModified"
          @click="reset"
        >
          Annuler
        </button>
      </div>
    </div>
    <!--end col-->
  </form>
</template>
