<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import { mapGetters } from "vuex";

import axios from "@/config/axios";

export default {
  data() {
    return {
      current_configs: {},
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters("smtp", ["configs"]),
    isModified() {
      return (
        this.current_configs?.host !== this.configs.host ||
        this.current_configs?.port !== this.configs.port ||
        this.current_configs?.user !== this.configs.user ||
        this.current_configs?.password !== this.configs.password
      );
    },
  },
  beforeMount() {
    this.current_configs = { ...this.configs };
  },
  methods: {
    save() {
      axios
        .post("/smtp-configs", {
          ...this.current_configs,
        })
        .then(() => {
          this.$saSuccess(
            "Les paramètres SMTP ont été mis à jour avec succès",
            ""
          );
        })
        .catch((err) => {
          this.$saError("Une erreur s'est produite", err.message);
        });
    },
    reset() {
      this.current_configs = { ...this.configs };
    },
  },
};
</script>

<template>
  <form action="javascript:void(0);" @submit.prevent="save">
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="smtp_host" class="form-label">SMTP Host</label>
          <input
            type="text"
            class="form-control"
            id="smtp_host"
            placeholder="Ex: smtp.gmail.com"
            v-model="current_configs.host"
          />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="smtp_port" class="form-label">SMTP Port</label>
          <input
            type="text"
            class="form-control"
            id="smtp_port"
            placeholder="Ex: 587"
            v-model="current_configs.port"
          />
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="smtp_user" class="form-label">SMTP User</label>
          <input
            type="text"
            class="form-control"
            id="smtp_user"
            placeholder="Ex: john.doe@gmail.com"
            v-model="current_configs.user"
          />
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <!-- with hide and show password -->

          <label for="smtp_password" class="form-label">SMTP Password</label>
          <div class="input-group input-group-merge">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              id="smtp_password"
              placeholder="Entrez le mot de passe"
              v-model="current_configs.password"
            />
            <div
              class="input-group-text"
              data-password="false"
              @click="showPassword = !showPassword"
            >
              <span
                :class="showPassword ? 'ri-eye-fill' : 'ri-eye-off-fill'"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!--end col-->
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button class="btn btn-success" @click="save" :disabled="!isModified">
            Enregistrer les modifications
          </button>
          <button
            type="button"
            class="btn btn-soft-warning"
            @click="reset"
            :disabled="!isModified"
          >
            Annuler
          </button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>
